import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import CallIcon from '../icons/CallIcon';
// import InstagramIcon from '../icons/InstagramIcon';
import SMSIcon from '../icons/SMSIcon';
import TelegramIcon from '../icons/TelegramIcon';
import WhatsAppLogo from '../icons/WhatsAppLogo';
import VkIcon from '../icons/VkIcon';

const contacts = [
  {
    key: 'whatsapp',
    Icon: WhatsAppLogo,
    label: 'WhatsApp',
    link: 'https://api.whatsapp.com/send?phone=+79234228882',
  },
  {
    key: 'telegram',
    Icon: TelegramIcon,
    label: 'Telegram',
    link: 'https://tlgg.ru/@Evgenia_Kuratova',
  },
  // {
  //   key: 'instagram',
  //   Icon: InstagramIcon,
  //   label: 'Instagram',
  //   link: 'https://instagram.com/zarenica',
  // },
  {
    key: 'vk',
    Icon: VkIcon,
    label: 'ВКонтакте',
    link: 'https://vk.com/zarenicanet',
  },
  {
    key: 'sms',
    Icon: SMSIcon,
    label: 'SMS',
    link: 'sms:+79234228882',
  },
  {
    key: 'tel',
    Icon: CallIcon,
    label: 'Позвонить',
    link: 'tel:+79234228882',
  },
];

export default function Contacts() {
  return (
    <List>
      {contacts.map(contact => (
        <ListItem disablePadding key={contact.key}>
          <ListItemButton
            component="a"
            href={contact.link}
            // LinkComponent={{ href: contact.link }}
          >
            <ListItemIcon>
              <contact.Icon />
            </ListItemIcon>
            <ListItemText primary={contact.label} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
}
