import type { SVGProps } from 'react';

function VkIcon(props: SVGProps<any>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.469 9c.28 0 .385.124.49.43.535 1.574 1.437 2.95 1.808 2.95.125 0 .189-.052.2-.323l.002-.098v-1.628c-.04-.75-.434-.81-.434-1.077a.26.26 0 01.216-.248L10.813 9h1.72c.206 0 .292.099.309.318l.003.088v2.19c0 .234.1.316.17.316.14 0 .255-.082.51-.34.788-.89 1.345-2.26 1.345-2.26a.465.465 0 01.388-.307L15.346 9h1.093c.331 0 .4.172.331.406-.128.595-1.274 2.257-1.45 2.509l-.022.032c-.116.188-.162.281 0 .492.116.164.499.492.754.796.47.534.824.985.922 1.296.083.285-.041.442-.303.466l-.075.003h-1.094c-.417 0-.54-.336-1.282-1.086-.649-.632-.927-.714-1.089-.714-.198 0-.271.051-.284.292l-.002.087v.991c0 .272-.086.43-.782.43-1.159 0-2.432-.71-3.336-2.022C7.37 11.058 7 9.605 7 9.313c0-.148.05-.283.288-.309L7.375 9h1.094z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.754 3.1h2.492c1.27 0 2.288 0 3.105.078.841.08 1.563.248 2.21.644a4.9 4.9 0 011.617 1.618c.396.646.564 1.368.644 2.209.078.817.078 1.835.078 3.105v2.492c0 1.27 0 2.288-.078 3.106-.08.84-.248 1.562-.644 2.208a4.9 4.9 0 01-1.618 1.618c-.646.396-1.368.564-2.209.644-.817.078-1.835.078-3.105.078h-2.492c-1.27 0-2.288 0-3.106-.078-.84-.08-1.562-.248-2.208-.644a4.9 4.9 0 01-1.618-1.618c-.396-.646-.564-1.368-.644-2.208-.078-.818-.078-1.836-.078-3.106v-2.492c0-1.27 0-2.288.078-3.105.08-.841.248-1.563.644-2.21A4.9 4.9 0 015.44 3.823c.646-.396 1.368-.564 2.208-.644.818-.078 1.836-.078 3.106-.078zM7.82 4.97c-.71.067-1.123.193-1.439.387A3.1 3.1 0 005.357 6.38c-.194.316-.32.73-.388 1.439-.068.722-.07 1.654-.07 2.981v2.4c0 1.327.002 2.259.07 2.981.068.71.194 1.123.388 1.439a3.1 3.1 0 001.023 1.023c.316.194.73.32 1.439.387.722.07 1.654.07 2.98.07h2.4c1.328 0 2.26 0 2.982-.07.709-.067 1.123-.193 1.439-.387a3.1 3.1 0 001.023-1.023c.194-.316.32-.73.387-1.439.069-.722.07-1.654.07-2.981v-2.4c0-1.327-.001-2.259-.07-2.981-.067-.71-.194-1.123-.387-1.439a3.1 3.1 0 00-1.023-1.023c-.316-.194-.73-.32-1.439-.387-.722-.069-1.654-.07-2.981-.07h-2.4c-1.327 0-2.259.001-2.981.07z"
        fill="currentColor"
      />
    </svg>
  );
}

export default VkIcon;
