import { useState } from 'react';
import { styled } from '@mui/material/styles';
import Fab, { fabClasses } from '@mui/material/Fab';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Typography from '@mui/material/Typography';

import type { MouseEvent } from 'react';

import Contacts from './components/Contacts';
import ContactUsIcon from './icons/ContactUsIcon';

const Root = styled('div')(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(4),
  right: theme.spacing(4),
  // Костыль. Там везде 2 zIndex
  zIndex: 2,
}));

const Header = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: '#F36F42',
  color: '#fff',
  borderTopLeftRadius: 4,
  borderTopRightRadius: 4,
}));

const StyledFab = styled(Fab)(({ theme }) => ({
  [`&.${fabClasses.root}`]: { backgroundColor: 'rgba(220, 178, 60, 0.91)', color: '#434343' },
}));

function App() {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(previousOpen => !previousOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Root>
      <StyledFab aria-describedby="contact-us-popup" onClick={handleClick}>
        <ContactUsIcon />
      </StyledFab>

      <Popper
        // там с zIndex'ами фигня сделана, поэтому нужно без портала
        disablePortal
        transition
        id="contact-us-popup"
        placement="top-start"
        open={open}
        anchorEl={anchorEl}
        modifiers={[{ name: 'offset', options: { offset: [0, 16] } }]}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <div>
                  <Header>
                    <Typography variant="subtitle1">Связаться с нами</Typography>
                  </Header>
                  <Contacts />
                </div>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </Root>
  );
}

export default App;
